import '../../app/vendors/css/forms/select/select2.min.css';
import '../../app/css/plugins/forms/pickers/form-flat-pickr.css';
import '../../app/vendors/css/pickers/flatpickr/flatpickr.min.css';

import 'magnific-popup/dist/magnific-popup.css';
import magnificPopup from 'magnific-popup';
import flatpickr from "flatpickr";
import select2 from 'select2';
import feather from "feather-icons";
import {swalError, swalSuccess, swalUnknown, toggleLoading} from "../functions";
import _ from "lodash";

function onChangeProductForm_2389169c(event) {
    toggleLoading('#saveButtonCPS', '#loadingButtonCPS');
    event.preventDefault();
    let form = $(this);
    let helper = $('#helper');
    let inputs = form.find("input, select, button, textarea");
    let fd = new FormData(form[0]);
    let url = helper.data('edit');
    inputs.prop("disabled", true);

    $.ajax({
        url: url,
        type: "post",
        data: fd,
        async: true,
        cache: false,
        contentType: false,
        processData: false
    }).done(function (response) {
        if (response.success) {
            $('#myStockTable').data('table').ajax.reload(null, false);
            form.removeClass("was-validated");
            swalSuccess(response.data);
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButtonCPS', '#loadingButtonCPS');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

function onSaveButtonCPS_2389169c(event) {
    let form = $("#change-product-substock-form");

    if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }

    form.addClass('was-validated');
}

function onAttributeChange_2389169c() {
    $('#myStockTable').data('table').ajax.reload(null, false);
}

function onChangeGrossPrice_2389169c(event) {
    let current = parseFloat($(event.target).val());
    let vatRate = parseFloat($('#stock_vatRateId option:selected').data('rate')) / 100 + 1;

    $('#stock_netPrice').val((current / vatRate).toFixed($('#stock_currency option:selected').data('digits')));
}

function onChangeNetPrice_2389169c(event) {
    let current = parseFloat($(event.target).val());
    let vatRate = parseFloat($('#stock_vatRateId option:selected').data('rate')) / 100 + 1;

    $('#stock_grossPrice').val((current * vatRate).toFixed($('#stock_currency option:selected').data('digits')));
}

function onChangeVatRate_2389169c() {
    let rate = parseFloat($('#stock_vatRateId option:selected').data('rate'));

    $('#stock_netPrice').val((parseFloat($('#stock_grossPrice').val()) / (rate / 100 + 1)).toFixed($('#stock_currency option:selected').data('digits')));
}

function onScannerToggle_2389169c() {
    $('.scanner-toggle').toggleClass('btn-outline-primary btn-primary');
}

export function initAwaitingAcquistionPage() {
    require('../datatables');
    require('../barcode-scanner');

    let helper = $('#helper');
    let table = $('#myStockTable');
    let productType = $("#product_type");
    let productCategory = $("#product_category");

    window.magnificPopup = magnificPopup;
    window.flatpickr = flatpickr;
    window.select2 = select2;

    if ($('html').attr('lang') === 'hu') {
        const hu = require("flatpickr/dist/l10n/hu").default.hu;
        flatpickr.localize(hu);
        require('select2/dist/js/i18n/hu.js');
    }

    window.changeInput = function (id) {
        $.ajax({
            url: helper.data('form'),
            type: "post",
            async: true,
            data: {"ID": id}
        }).done(function (response) {
            $("#change-product-substock-modal-form").html(response.data);

            $('.select2').select2({
                dropdownParent: $('#change-product-substock-modal')
            });

            $('.datepicker').flatpickr({
                dateFormat: "Y.m.d.",
                allowInput: true
            });
        }).fail(function (e) {
            swalUnknown(e);
        });
    };

    table.data('table', table.DataTable({
        "dom": '<"card-header border-bottom p-1"<"head-label"><"dt-action-buttons text-end"B>><"d-flex justify-content-between align-items-center mx-0 row"<"col-sm-12 col-md-6"l><"col-sm-12 col-md-6"f>>t<"d-flex justify-content-between mx-0 row"<"col-sm-12 col-md-6"i><"col-sm-12 col-md-6"p>>',
        "lengthMenu": [[10, 25, 50, 100, 500, 1000, -1], [10, 25, 50, 100, 500, 1000, helper.data('all')]],
        "pageLength": 25,
        "ajax": {
            "url": helper.data('table'),
            "async": true,
            "data": function (d) {
                d.reset = false;
                d.product_type = productType.val();
                d.product_category = productCategory.val();
            },
            "cache": false,
            "method": 'GET',
            "pages": 2
        },
        "order": [[3, 'desc'], [2, 'asc']],
        "language": {
            "url": helper.data('json')
        },
        "rowGroup": {
            "dataSrc": 2
        },
        "deferRender": true,
        "processing": true,
        "serverSide": true,
        "searchDelay": 750,
        "stateSave": true,
        "paging": true,
        "responsive": true,
        "buttons": [
            {
                text: '<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAAv0lEQVRIie3UsUoCUBTG8R9pgw6BoNATBA425ezkpoMgNPcMPUCrzxA4+ASBuDU3CSqCg5OLEoHQVkRLg0dwkkBEh/vB5XK43z3/wxk+kk6tTNwPKKGJNpZ4xn2cAq5xhwoe8YYO5ujiBj/x/wo1TLIBuMUlqiijj9bOIGvkUIy6gSfU0QtvHrPo8R19XBy+hP1KgARIgAQ4B8A2Tac2EZ3Du016vuz4RvH+EfUAX3jFZ3jHWGGIBX6PPHvSP/UHfIkfxOP+xdYAAAAASUVORK5CYII=" alt=""/>',
                className: 'btn btn-primary me-2 py-50 px-1 scanner-toggle',
                init: function (api, node) {
                    $(node).removeClass('btn-secondary');
                }
            },
            {
                extend: 'colvis',
                columns: ':not(.no-colvis)',
                text: helper.data('colvis'),
            },
            {
                extend: 'collection',
                className: 'btn btn-outline-secondary me-2',
                text: feather.icons.share.toSvg({class: 'font-small-4 me-50'}) + helper.data('export'),
                buttons: [
                    {
                        extend: 'print',
                        text: feather.icons.printer.toSvg({class: 'font-small-4 me-50'}) + helper.data('print'),
                        className: 'dropdown-item',
                        exportOptions: {
                            columns: [2, 3, 4, 5, 6, 7, 8],
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null
                            }
                        },
                        orientation: 'landscape',
                        customize: function (win) {
                            let css = '@page { size: landscape; }',
                                head = win.document.head || win.document.getElementsByTagName('head')[0],
                                style = win.document.createElement('style');
                            style.type = 'text/css';
                            style.media = 'print';
                            if (style.styleSheet) {
                                style.styleSheet.cssText = css;
                            } else {
                                style.appendChild(win.document.createTextNode(css));
                            }
                            head.appendChild(style);
                        }
                    },
                    {
                        extend: 'csvHtml5',
                        text: feather.icons['file-text'].toSvg({class: 'font-small-4 me-50'}) + 'Csv',
                        className: 'dropdown-item',
                        exportOptions: {
                            columns: [2, 3, 4, 5, 6, 7, 8],
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null
                            }
                        }
                    },
                    {
                        extend: 'excelHtml5',
                        text: feather.icons.file.toSvg({class: 'font-small-4 me-50'}) + 'Excel',
                        className: 'dropdown-item',
                        exportOptions: {
                            columns: [2, 3, 4, 5, 6, 7, 8],
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null
                            }
                        }
                    },
                    {
                        extend: 'pdfHtml5',
                        text: feather.icons.clipboard.toSvg({class: 'font-small-4 me-50'}) + 'Pdf',
                        className: 'dropdown-item',
                        exportOptions: {
                            columns: [2, 3, 4, 5, 6, 7, 8],
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null
                            }
                        }
                    },
                    {
                        extend: 'copyHtml5',
                        text: feather.icons.copy.toSvg({class: 'font-small-4 me-50'}) + helper.data('copy'),
                        className: 'dropdown-item',
                        exportOptions: {
                            columns: [2, 3, 4, 5, 6, 7, 8],
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null
                            }
                        }
                    }
                ],
                init: function (api, node) {
                    $(node).removeClass('btn-secondary');
                    $(node).parent().removeClass('btn-group');
                    setTimeout(function () {
                        $(node).closest('.dt-buttons').removeClass('btn-group').addClass('d-inline-flex');
                    }, 50);
                }
            }
        ],
        "columnDefs": [
            {
                'targets': 'no-sort',
                'orderable': false
            },
            {
                targets: 4,
                responsivePriority: 2,
            },
            {
                targets: 3,
                responsivePriority: 1
            },
            {
                className: 'control',
                orderable: false,
                targets: 0
            }
        ],
    }).on('draw.dt', function () {
        $('.dataTables_filter input').removeClass('form-control-sm').addClass('form-control');
        $('.dataTables_length select').removeClass('form-select-sm').removeClass('form-control-sm').addClass('form-select');
        $("td").each(function () {
            $(this).addClass("text-center");
        });
        $('.image-popup-vertical-fit').magnificPopup({
            type: 'image',
            closeOnContentClick: true,
            mainClass: 'mfp-img-mobile',
            image: {verticalFit: true}
        });
        feather.replace({
            width: 14,
            height: 14
        });
    }).on('responsive-display', function () {
        $('.image-popup-vertical-fit').magnificPopup({
            type: 'image',
            closeOnContentClick: true,
            mainClass: 'mfp-img-mobile',
            image: {verticalFit: true}
        });

        feather.replace({
            width: 14,
            height: 14
        });
    }).on('preInit', function () {
        $('.buttons-colvis').addClass('btn btn-outline-secondary me-1');
    }).on('buttons-action', function () {
        $('.buttons-columnVisibility').addClass('dropdown-item');
    }));

    let search = $('input[type=search].form-control');

    search.on("keyup", _.debounce(function () {
        table.search(search.val()).draw();
    }, 500));

    $(document).codeScanner({
        onScan: function ($element, code) {
            if ($('.scanner-toggle').hasClass('btn-primary')) {
                $('.dataTables_filter label input').val(code);
                table.DataTable().search(code).draw();
            }
        }
    });

    productType.on('change', onAttributeChange_2389169c);
    productCategory.on('change', onAttributeChange_2389169c);
    $("#saveButtonCPS").on("click", onSaveButtonCPS_2389169c);
    $("#change-product-substock-form").on('submit', onChangeProductForm_2389169c);

    $(document).on("input", "#stock_grossPrice", onChangeGrossPrice_2389169c);
    $(document).on("input", "#stock_netPrice", onChangeNetPrice_2389169c);
    $(document).on("input", "#stock_vatRateId", onChangeVatRate_2389169c);
    $(document).on('click', '.scanner-toggle', onScannerToggle_2389169c);
}

export function unloadAwaitingAcquistionPage() {
    delete window.magnificPopup;
    delete window.flatpickr;
    delete window.select2;
    delete window.changeInput;

    $('#myStockTable').DataTable().destroy();
    $('.flatpickr-calendar').remove();

    $('.select2').select2('destroy');
    $("#product_type").off('change', onAttributeChange_2389169c);
    $("#product_category").off('change', onAttributeChange_2389169c);
    $("#saveButtonCPS").off("click", onSaveButtonCPS_2389169c);
    $("#change-product-substock-form").off('submit', onChangeProductForm_2389169c);

    $(document).off("input", "#stock_grossPrice", onChangeGrossPrice_2389169c);
    $(document).off("input", "#stock_netPrice", onChangeNetPrice_2389169c);
    $(document).off("input", "#stock_vatRateId", onChangeVatRate_2389169c);
    $(document).off('click', '.scanner-toggle', onScannerToggle_2389169c);
}
